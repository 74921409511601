import React, { memo, useCallback } from 'react';
import { NumberScreenComponent } from '../components/number';
import { ReceivedQuestionComponentNumber }
  from '@modules/learning/types/objects';
import { FormSubmissionValues, SetFormSubmissionValues }
  from '@modules/forms/types';

type NumberProps = {
  item: ReceivedQuestionComponentNumber;
  setValues: SetFormSubmissionValues;
  value: string;
  readOnly?: boolean;
};

const Number = memo(({
  item, setValues, value, readOnly = false
}: NumberProps) => {
  const onChange = useCallback((n: string) => {
    setValues((values: FormSubmissionValues) => {
      return {
        ...values,
        [item.id]: n
      };
    });
  }, [setValues, item]);

  return (
    <NumberScreenComponent
      item={item}
      value={value}
      onChange={readOnly ? undefined : onChange}
    />
  );
});

export default Number;
