import React, { Dispatch, SetStateAction, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Button } from '@common/components/button';
import { EmployeeSurveyDetail } from '@modules/survey/types/objects';
import { FormSubmissionValues } from '@modules/forms/types';
import { formatAnswersToSend } from '@common/components/form/user-response-fields/utils';
import Api from '@common/services/api';
import { AlertService } from '@common/services/alert';
import { useHistoryActionWithoutConfirmation } from '@common/components/confirm-button/utils';
import { useSurveyEditorErrorHandling } from './utils';

type SurbeySubmissionButtonsProps = {
  currentPage: number;
  survey: EmployeeSurveyDetail;
  networkId: string;
  values: FormSubmissionValues[];
  orgId: string;
  submitting: boolean;
  setSubmitting: Dispatch<SetStateAction<boolean>>;
};

const NextButton = memo(({
  currentPage,
  survey,
  networkId,
  values,
  orgId,
  submitting,
  setSubmitting
}: SurbeySubmissionButtonsProps) => {
  const { t } = useTranslation();

  const surveyId = survey.id;
  const screensAmount = survey.screens?.length;

  const { pathname, search } = useLocation();
  const currentUrl = `${pathname}${search}`;

  const nextStepUrl = useMemo(() => {
    const nextPage = currentPage + 1;

    return nextPage <= screensAmount ?
      `/networks/${networkId}/survey/${surveyId}/${nextPage}${search}` :
      null;
  }, [currentPage, screensAmount, surveyId, networkId, search]);

  const { question } = survey.screens[currentPage - 1];

  const isRequired = !!question?.settings?.answer_required;

  const hasInput = useMemo(() => {
    if (!question) return false;
    const value = values[currentPage - 1][question.id];
    return Array.isArray(value) ? value.length > 0 : !!value;
  }, [question, currentPage, values]);

  const nextDisabled = !nextStepUrl || (isRequired && !hasInput);

  const feedUrl = `/networks/${networkId}/feed`;

  const goToFeed = useHistoryActionWithoutConfirmation(feedUrl);
  const handleError = useSurveyEditorErrorHandling(feedUrl);

  const onSubmit = async () => {
    try {
      setSubmitting(true);
      const url = `/v1/organisations/${orgId}/users/me/surveys/${surveyId}/respond`;
      await Api.post(url, {
        answers: formatAnswersToSend(Object.assign({}, ...values), survey)
      });
      AlertService.success(t('survey:response_submitted'));
      goToFeed();
    } catch (error: any) {
      handleError(error);
    } finally {
      setSubmitting(false);
    }
  };

  if (nextStepUrl) {
    return (
      <Link to={nextDisabled ? currentUrl : nextStepUrl}>
        <Button
          size="large"
          iconRight="chevron_right"
          type="primary"
          className="SurveySubmissionEditor__next nextButton"
          disabled={nextDisabled}
        >
          { (isRequired || hasInput || !question) ? t('common:next') : t('common:skip') }
        </Button>
      </Link>
    );
  }
  return (
    <div className="submitContainer">
      <Button
        size="large"
        type="primary"
        className="nextButton"
        disabled={isRequired && !hasInput}
        isLoading={submitting}
        onClick={onSubmit}
      >
        { t('common:submit') }
      </Button>
    </div>
  );
});

export default NextButton;
