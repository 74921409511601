import React, { memo, useEffect, useState, useMemo } from 'react';
import Container from '@common/components/container';
import { Overview, OverviewContent } from '@common/components/overview';
import { useAppSelector } from '@common/hooks';
import { selected } from '@modules/core/selectors/logged-user';
import { Api } from '@common/services/api';
import { APILearningOverviewResponse } from '@modules/learning/types/objects';
import EmployeeBadge from './employee-badge';

type EmployeeAcademyProps = {
  orgId: string;
};

const EmployeeAcademy = memo(({ orgId }: EmployeeAcademyProps) => {
  const loggedUser = useAppSelector(selected);

  const [learningRes, setLearningRes] = useState<APILearningOverviewResponse | null>(null);

  useEffect(() => {
    const url = `/v2/organisations/${orgId}/users/me/learning`;
    Api.get<APILearningOverviewResponse>(url).then((res) => {
      setLearningRes(res);
    });
  }, [orgId, setLearningRes]);

  const totalUserPoints = useMemo(() => {
    if (learningRes) {
      let points = 0;
      if (typeof learningRes.data?.learning?.current_points === 'number') {
        points += learningRes.data.learning.current_points;
      }
      if (typeof learningRes.data?.onboarding?.current_points === 'number') {
        points += learningRes.data.onboarding.current_points;
      }
      return points;
    }
    return null;
  }, [learningRes]);

  return (
    <Container name="EmployeeAcademy">
      <Container.Content horizontal>
        <Overview>
          <OverviewContent>
            <EmployeeBadge
              points={totalUserPoints}
              loading={typeof totalUserPoints !== 'number'}
              loggedUser={loggedUser}
            />
          </OverviewContent>
        </Overview>
      </Container.Content>
    </Container>
  );
});

export default EmployeeAcademy;
