import React, {
  memo, useCallback, useEffect, useMemo, useState,
} from 'react';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router';
import { useSelector } from 'react-redux';

import { Container } from '@common/components/container';
import { Breadcrumb, TopNavigationBar } from '@common/components/navigation-bar';
import Spinner from '@common/components/spinner';
import * as organisationSelector from '@modules/organisation/selectors/organisation';
import {
  APIForm, APIFormSubmission, EFormSubmissionStatuses, FormSubmissionValues,
} from '@modules/forms/types';
import Api from '@common/services/api';
import { ApiResponse } from '@common/services/api/types';
import { ScreenComponentItem } from '@modules/learning/types/objects';
import { Button } from '@common/components/button';
import {
  getEditableComponents, getInitialValue, formatReceivedValues, useSubmissionSubmit
} from '../../../../common/components/form/user-response-fields/utils';
import { useTimezone } from '@common/hooks/time';
import { useAppSelector } from '@common/hooks';
import ConfirmButton from '@common/components/confirm-button';
import { AlertService } from '@common/services/alert';
import CommentsIcon from './submission-comments/comments-icon';
import FormSubmissionEditorContent from './form-submission-editor-content';
import { FormIcon } from '../form-icon';

import './form-submission-editor.scss';

type FormSubmissionEditorProps = {
  formId: string;
  networkId: string;
  originPageHref: string;
  originPageName: string;
  readOnly?: boolean;
  submissionId?: string;
};

const FormSubmissionEditor = memo(({
  formId, networkId, submissionId, originPageHref, originPageName,
  readOnly = false,
}: FormSubmissionEditorProps) => {

  const [formResponse, setFormResponse] =
    useState<ApiResponse<APIForm> | null>(null);
  const [submissionResponse, setSubmissionResponse] =
    useState<ApiResponse<APIFormSubmission> | null>(null);
  const [timezone, setTimezone] = useState<string | null>(null);
  const [values, setValues] = useState<FormSubmissionValues | null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const { t } = useTranslation();
  const locale = useAppSelector((state) => state.loggedUser.user.language.locale);
  const history = useHistory();
  const editMode = !!submissionId;

  const orgId = useSelector(organisationSelector.selected).id;
  const clientTimezone = useTimezone();
  useEffect(() => {
    const url = `/v1/organisations/${orgId}/users/me/forms/${formId}`;
    Api.get<ApiResponse<APIForm>>(url).then((response) => {
      setFormResponse(response);
      if (editMode) return;

      const components = getEditableComponents(response.data);
      const defaultValues = components.map((component: ScreenComponentItem) => {
        return { [component.id]: getInitialValue(component) };
      });
      setValues(Object.assign({}, ...defaultValues));
      setTimezone(clientTimezone);
    });
  }, [orgId, formId, setFormResponse, setValues, editMode, clientTimezone]);

  useEffect(() => {
    if (!submissionId) return;

    Api.get<ApiResponse<APIFormSubmission>>(
      `/v1/organisations/${orgId}/users/me/forms/submissions/${submissionId}`
    ).then((response: ApiResponse<APIFormSubmission>) => {
      setSubmissionResponse(response);
      setValues(formatReceivedValues(response.data, locale));
      setTimezone(response.data.timezone.name);
    });
  }, [setSubmissionResponse, setValues, setTimezone, submissionId, orgId]);

  const form = formResponse?.data;
  const submission = submissionResponse?.data;
  const formTitle = form?.title;

  const breadCrumbs = useMemo(() => {
    const base: Breadcrumb[] = [
      {
        name: originPageName,
        path: originPageHref
      }
    ];
    if (formTitle) {
      base.push({ name: formTitle });
    }
    return base;
  }, [originPageHref, originPageName, formTitle, t]);

  const ready = form && values && timezone && (editMode ? submission : true);
  const draft = !!submissionId && !readOnly;

  const [submitting, setSubmitting] = useState<boolean>(false);
  const submit = useSubmissionSubmit(
    values, form, setSubmitting, orgId, networkId, submissionId, originPageHref,
    timezone
  );

  const onSaveAndClose = useCallback(() => {
    return submit(EFormSubmissionStatuses.DRAFT);
  }, [submit]);

  const onSubmit = useCallback(() => {
    return submit(EFormSubmissionStatuses.PUBLISHED);
  }, [submit]);

  const onDelete = useCallback(async () => {
    const url = `/v1/organisations/${orgId}/users/me/forms/submissions/${submissionId}`;
    try {
      await Api.delete(url);
      const msg = t('forms:submission_draft_delete_success_message');
      AlertService.success(msg);
      history.push(`/networks/${orgId}/forms/me/drafts`);
    } catch (error: unknown) {
      AlertService.error(t('forms:submission_draft_delete_error_message'));
      throw error;
    }
  }, [submissionId]);

  const isSubmitted = !!submissionId && readOnly;

  useEffect(() => {
    if (values) {
      const files = Object.keys(values)
        .filter((key) => Array.isArray(values[key]) && (values[key] as any[]).find((v) => v instanceof File));
      setIsUploading(!!files?.length);
    }
  }, [values]);

  return (
    <Container name="EditForm" className="FormSubmissionEditor">
      <TopNavigationBar
        breadcrumbs={breadCrumbs}
        title={
          (
            ready && [
              <FormIcon form={form} />,
              form.title
            ]
          ) ||
          <span className="Survey__Title">
            <Spinner size="large" />
          </span>
        }
        action={
          ready && (
            <div className="responseActions">
              {
                isSubmitted && (
                  <CommentsIcon
                    submissionId={submissionId}
                    organisationId={orgId}
                    commentsCount={submission?.comments_count}
                  />
                )
              }
              {
                draft && (
                  <ConfirmButton
                    danger
                    title={(
                      <Trans
                        i18nKey="forms:submission_draft_delete_confirm_message"
                        values={{ formName: form.title }}
                      />
                    )}
                    onConfirm={onDelete}
                  >
                    <Button size="large" icon="delete" />
                  </ConfirmButton>
                )
              }
              <Link to={originPageHref}>
                <Button size="large">{ t('common:back') }</Button>
              </Link>
              {
                !readOnly && (
                  <>
                    <Button
                      disabled={isUploading}
                      isLoading={submitting}
                      onClick={onSaveAndClose}
                      size="large"
                    >
                      { t('common:save_and_close') }
                    </Button>
                    <Button
                      disabled={isUploading}
                      isLoading={submitting}
                      onClick={onSubmit}
                      type="primary"
                      size="large"
                    >
                      { t('common:submit') }
                    </Button>
                  </>
                )
              }
            </div>
          )
        }
      />
      {/** we want to have a white background, but only here */}
      <style>{'body { background-color: inherit; }'}</style>
      {
        ready ?
          <FormSubmissionEditorContent
            form={form}
            values={values}
            // @ts-expect-error
            setValues={setValues}
            setTimezone={setTimezone}
            timezone={timezone!}
            onSubmit={onSubmit}
            uploading={isUploading}
            submitting={submitting}
            readOnly={readOnly}
          /> :
          <Spinner size="large" centered />
      }
    </Container>
  );
});

type BaseEditorProps = RouteComponentProps<{
  formId: string;
  networkId: string;
}>;

type SubmissionCreationEditorProps = BaseEditorProps;
export const SubmissionCreationEditor = memo((
  props: SubmissionCreationEditorProps
) => {
  const { formId, networkId } = props.match.params;
  const { t } = useTranslation();
  return (
    <FormSubmissionEditor
      formId={formId}
      networkId={networkId}
      originPageHref={`/networks/${networkId}/forms/me/forms`}
      originPageName={t('forms:user_forms')}
    />
  );
});

type SubmissionDraftEditorProps = BaseEditorProps & RouteComponentProps<{
  submissionId: string;
}>;
export const SubmissionDraftEditor = memo((
  props: SubmissionDraftEditorProps
) => {
  const { formId, networkId, submissionId } = props.match.params;
  const { t } = useTranslation();
  return (
    <FormSubmissionEditor
      formId={formId}
      networkId={networkId}
      submissionId={submissionId}
      originPageHref={`/networks/${networkId}/forms/me/drafts`}
      originPageName={t('forms:user_drafts')}
    />
  );
});

type SubmissionViewerProps = BaseEditorProps & RouteComponentProps<{
  submissionId: string;
}>;
export const SubmissionViewer = memo((props: SubmissionViewerProps) => {
  const { formId, networkId, submissionId } = props.match.params;
  const { t } = useTranslation();
  return (
    <FormSubmissionEditor
      formId={formId}
      networkId={networkId}
      submissionId={submissionId}
      readOnly
      originPageHref={`/networks/${networkId}/forms/me/user-submissions`}
      originPageName={t('forms:user_submissions')}
    />
  );
});

export default FormSubmissionEditor;
