import React, { memo, useCallback } from 'react';
import { FormSubmissionValues, SetFormSubmissionValues }
  from '@modules/forms/types';
import { FileUploadScreenComponent, FileUploadScreenComponentValue }
  from '../components/file-upload';
import { ReceivedQuestionComponentFileUpload }
  from '@modules/learning/types/objects';

type FileUploadProps = {
  item: ReceivedQuestionComponentFileUpload;
  setValues: SetFormSubmissionValues;
  value: FileUploadScreenComponentValue;
  readOnly?: boolean;
};

const FileUpload = memo(({
  item, value, setValues, readOnly = false
}: FileUploadProps) => {

  // console.log('debug FileUpload item', item);

  const onChange = useCallback((newValue: FileUploadScreenComponentValue) => {
    setValues((values: FormSubmissionValues) => {
      return {
        ...values,
        [item.id]: newValue
      };
    });
  }, [setValues, item]);

  return (
    <FileUploadScreenComponent
      item={item}
      onChange={readOnly ? undefined : onChange}
      value={value}
      dropzoneCallToAction="survey:question_type_file_upload_preview_dropzone_label_click"
    />
  );
});

export default FileUpload;
