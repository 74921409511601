import React, { memo } from 'react';
import { ScreenExternalVideoItem } from '@modules/learning/types/objects';
import VideoComponent from '../components/video';

type ExternalVideoProps = {
  item: ScreenExternalVideoItem;
};

const ExternalVideo = memo((props: ExternalVideoProps) => {
  const videoId = props?.item?.parameters?.attachment?.video_id;
  const href = videoId && `https://www.youtube.com/watch?v=${videoId}`;
  return (
    <a
      role="button"
      rel="noreferrer"
      className="ExternalVideo"
      href={href}
      target="_blank"
    >
      <VideoComponent {...props} />
    </a>
  );
});

export default ExternalVideo;
