import React, { memo, useCallback } from 'react';
import { FormSubmissionValues, SetFormSubmissionValues }
  from '@modules/forms/types';
import { BooleanScreenComponent, BooleanScreenComponentValue }
  from '@common/components/form/user-response-fields/components/boolean';
import { ReceivedQuestionComponentBoolean }
  from '@modules/learning/types/objects';

type BooleanProps = {
  item: ReceivedQuestionComponentBoolean;
  setValues: SetFormSubmissionValues;
  value: BooleanScreenComponentValue;
  readOnly?: boolean;
};

const Boolean = memo(({
  item, value, setValues, readOnly = false
}: BooleanProps) => {

  const onChange = useCallback((newValue: BooleanScreenComponentValue) => {
    setValues((values: FormSubmissionValues) => {
      return {
        ...values,
        [item.id]: newValue
      };
    });
  }, [setValues, item]);

  return (
    <BooleanScreenComponent
      item={item}
      onChange={readOnly ? undefined : onChange}
      value={value}
    />
  );
});

export default Boolean;
