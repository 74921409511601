import React, { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useLocation, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { Container } from '@common/components/container';
import { TopNavigationBar } from '@common/components/navigation-bar';
import { useAppSelector } from '@common/hooks';
import { selected } from '@modules/organisation/selectors/organisation';
import Api from '@common/services/api';
import { ApiResponse } from '@common/services/api/types';
import { EmployeeSurveyDetail } from '@modules/survey/types/objects';
import Spinner from '@common/components/spinner';
import { Button } from '@common/components/button';
import ProgressChart from '@common/components/proportion-chart/progress-chart';
import { getInitialValue } from '@common/components/form/user-response-fields/utils';
import { FormSubmissionValues } from '@modules/forms/types';
import { ScreenComponentItem } from '@modules/learning/types/objects';
import { useQueryParam } from '@common/hooks/url';
import SurveySubmissionSteps from './survey-submission-steps';
import NextButton from './next-button';
import { useSurveyEditorErrorHandling } from './utils';
import AnonymityBanner from './anonymity-banner';

import './survey-submission-editor.scss';

type SurveyResponse = ApiResponse<EmployeeSurveyDetail>;

type RouteParams = {
  surveyId: string;
  networkId: string;
  page: string;
};

const SurveySubmissionEditor = memo(() => {
  const { surveyId, networkId, page } = useRouteMatch<RouteParams>().params;

  // origin will be a url string if the user arrived to the editor by
  // clicking on an activity notification
  const origin = useQueryParam('origin');

  const orgId = useAppSelector(selected).id;

  const [survey, setSurvey] = useState<SurveyResponse | null>(null);

  const [values, setValues] = useState<null | FormSubmissionValues[]>(null);

  const timelinePath = `/networks/${networkId}/feed`;
  const editorCloseUrl = origin || timelinePath;
  const handleError = useSurveyEditorErrorHandling(editorCloseUrl);

  const history = useHistory();
  useEffect(() => {
    const path = `/v1/organisations/${orgId}/users/me/surveys/${surveyId}`;
    Api.get<SurveyResponse>(path).then((response) => {
      const defaultValues = response.data.screens.map(({ components }) => {
        const valuesList = components.map((component: ScreenComponentItem) => {
          return { [component.id]: getInitialValue(component) };
        });
        return Object.assign({}, ...valuesList);
      });
      setValues(defaultValues);
      setSurvey(response);
    }).catch(handleError);
  }, [orgId, surveyId, setSurvey, history, setValues, handleError]);

  const { t } = useTranslation();
  const firstStepUrl = `/networks/${networkId}/survey/${surveyId}/1`;
  const breadcrumbs = useMemo(() => {
    const timeline = {
      name: t('social:channels_my_timeline'),
      path: timelinePath
    };
    if (!survey) {
      return [timeline];
    }
    return [
      timeline,
      {
        name: survey.data.title,
        path: firstStepUrl
      }
    ];
  }, [t, survey, timelinePath, firstStepUrl]);

  const currentPage = parseInt(page, 10);

  const { search } = useLocation();
  const prevStepUrl = useMemo(() => {
    const prevPage = currentPage - 1;
    return prevPage >= 1 ?
      `/networks/${networkId}/survey/${surveyId}/${prevPage}${search}` :
      null;
  }, [currentPage, surveyId, networkId, search]);

  const stepsTotal = survey?.data?.screens?.length;

  const ready = !!(values && survey);

  const [submitting, setSubmitting] = useState<boolean>(false);

  if (
    (currentPage !== currentPage) || // NaN check
    currentPage < 1 ||
    (!values && currentPage > 1)
  ) {
    return <Redirect to={firstStepUrl} />;
  }

  return (
    <Container name="EditForm" className="FormSubmissionEditor SurveySubmissionEditor">
      {
        !ready ?
          <Spinner size="large" centered /> :
          <>
            <TopNavigationBar
              breadcrumbs={breadcrumbs}
              title={
                (
                  survey && survey.data.title
                ) ||
                <span className="Survey__Title">
                  <Spinner size="large" />
                </span>
              }
              action={
                <>
                  {
                    typeof stepsTotal === 'number' && (
                      <>
                        <span className="stepsCount">{currentPage}/{stepsTotal}</span>
                        <ProgressChart
                          stepsTotal={stepsTotal}
                          currentStep={currentPage - 1}
                        />
                      </>
                    )
                  }
                  <div className="responseActions">
                    <Link to={editorCloseUrl}>
                      <Button size="large">
                        {
                          origin ? t('common:back') : t('common:close')
                        }
                      </Button>
                    </Link>
                    {
                      prevStepUrl && (
                        <Link to={prevStepUrl}>
                          <Button
                            size="large"
                            icon="chevron_left"
                            className="SurveySubmissionEditor__previous"
                            type="primary"
                          >
                            { t('common:previous') }
                          </Button>
                        </Link>
                      )
                    }
                    <NextButton
                      survey={survey.data}
                      networkId={networkId}
                      currentPage={currentPage}
                      values={values}
                      orgId={orgId}
                      submitting={submitting}
                      setSubmitting={setSubmitting}
                    />
                  </div>
                </>
              }
            />
            <SurveySubmissionSteps
              networkId={networkId}
              orgId={orgId}
              survey={survey}
              values={values}
              setValues={setValues}
              currentPage={currentPage}
              submitting={submitting}
              setSubmitting={setSubmitting}
              className={
                survey.data?.settings?.is_anonymous ?
                  'FormSubmissionEditorContent--is_anonymous' :
                  ''
              }
            />
            {
              survey.data?.settings?.is_anonymous &&
              (
                <AnonymityBanner />
              )
            }
          </>
      }
    </Container>
  );
});

export default SurveySubmissionEditor;
