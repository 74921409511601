import React, { memo, useCallback } from 'react';
import { ReceivedQuestionComponentText } from '@modules/learning/types/objects';
import {
  FormSubmissionValues, SetFormSubmissionValues
} from '@modules/forms/types';
import QText, { QuestionTextValue } from '../components/question-text';

type QuestionTextProps = {
  item: ReceivedQuestionComponentText;
  setValues: SetFormSubmissionValues;
  value: QuestionTextValue;
  readOnly?: boolean;
};

const QuestionText = memo(({
  item, setValues, value, readOnly = false
}: QuestionTextProps) => {
  const onChange = useCallback((newValue: string) => {
    setValues((values: FormSubmissionValues | null) => {
      return {
        ...values,
        [item.id]: newValue
      };
    });
  }, [setValues, item]);
  return (
    <QText
      item={item}
      onChange={readOnly ? undefined : onChange}
      value={value}
    />
  );
});

export default QuestionText;
