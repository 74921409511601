import React, { memo, useEffect } from 'react';
import { FormSubmissionValues, SetFormSubmissionValues } from '@modules/forms/types';
import { APISurveyScreen } from '@modules/survey/types/objects';
import ScreenComponents from '@common/components/form/user-response-fields/screen-components';

type SurveySubmissionStepProps = {
  screen: APISurveyScreen;
  value: FormSubmissionValues;
  setValues: SetFormSubmissionValues;
};

const SurveySubmissionStep = memo(({
  screen,
  value,
  setValues
}: SurveySubmissionStepProps) => {

  useEffect(() => {
    // we want the step to be at the top of its viewport when
    // we navigate the survey
    window.scrollTo(0, 0);
  }, []);

  return (
    <ScreenComponents
      screen={screen}
      values={value}
      setValues={setValues}
      readOnly={false}
    />
  );

});

export default SurveySubmissionStep;
