import React, { PropsWithChildren, memo, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { APIForm, SetFormSubmissionValues, FormSubmissionValues } from '@modules/forms/types';
import { ScreenComponentItem } from '@modules/learning/types/objects';
import { getComponent } from '@common/components/form/user-response-fields/utils';
import { EComponentTypes } from '@modules/learning/definitions';
import { Button } from '@common/components/button';

type ScreenSectionProps = PropsWithChildren<{
  className?: string;
}>;
const ScreenSection = memo(({ children, className }: ScreenSectionProps) => {
  return (
    <div className={`Screen${className ? ` ${className}` : ''}`}>
      <div className="Screen__Inner">
        <div className="Screen__Content">
          <div className="List">
            { children }
          </div>
        </div>
      </div>
    </div>
  );
});

type FormSubmissionEditorContentProps = {
  form: APIForm;
  setValues: SetFormSubmissionValues;
  values: FormSubmissionValues;
  setTimezone: Dispatch<SetStateAction<string | null>>;
  timezone: string;
  onSubmit: () => void;
  submitting: boolean;
  uploading: boolean;
  readOnly: boolean;
};

const FormSubmissionEditorContent = memo(({
  form, values, setValues, onSubmit, submitting, uploading, readOnly, setTimezone, timezone
}: FormSubmissionEditorContentProps) => {

  const { t } = useTranslation();

  return (
    <div className="FormSubmissionEditorContent">
      {
        form.screens.map((screen) => {
          return (
            <ScreenSection key={screen.id}>
              {
                screen.components.map((component: ScreenComponentItem) => {
                  const Component = getComponent(component.type);
                  if (Component) {
                    const value = values[component.id];

                    let additionalProps = {};
                    if (component.type === EComponentTypes.QUESTION_DATE_TIME) {
                      additionalProps = { timezone, setTimezone };
                    }

                    return (
                      <div className="ScreenComponent" key={component.id}>
                        <Component
                          // @ts-expect-error
                          item={component}
                          // @ts-expect-error
                          value={value}
                          // @ts-expect-error
                          setValues={setValues}
                          // @ts-expect-error
                          readOnly={readOnly}
                          {...additionalProps}
                        />
                      </div>
                    );
                  }
                  return null;
                })
              }
            </ScreenSection>
          );
        })
      }
      <ScreenSection className="buttonsScreen">
        {
          !readOnly && (
            <Button
              disabled={uploading}
              isLoading={submitting}
              onClick={onSubmit}
              type="primary"
              size="large"
            >
              { t('common:submit') }
            </Button>
          )
        }
      </ScreenSection>
    </div>
  );
});

export default FormSubmissionEditorContent;
