import React, { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { LoggedUser } from '@common/types/objects';
import { Icon } from '@common/components/icon';
import Spinner from '@common/components/spinner';
import './employee-badge.scss';
import { isFinite } from 'lodash';

type EmployeeBadgeProps = {
  points?: number | null;
  loading?: boolean;
  loggedUser: LoggedUser;
};

const EmployeeBadge = memo(({ points, loggedUser, loading = false }: EmployeeBadgeProps) => {
  const { t } = useTranslation();
  return (
    <div className="EmployeeBadge">
      <img
        className="EmployeeBadge__Image"
        alt={t('common:profile_image')}
        src={loggedUser.profile_img || ''}
      />
      <div className="EmployeeBadge__Details">
        <h3 className="EmployeeBadge__Details__Title">
          <Trans
            i18nKey="learning:employee_badge_title"
            values={{ name: loggedUser.full_name }}
          />
        </h3>
        {
          loading ?

            <Spinner /> :

            isFinite(points) && (
              <div className="EmployeeBadge__Details__Points">
                <Icon
                  type="paid"
                  size="large"
                  customClassName="EmployeeBadge__Details__PointsIcon"
                />
                <h3>
                  <Trans i18nKey="common:points" values={{ count: points }} />
                </h3>
              </div>
            )
        }
      </div>
    </div>
  );
});

export default EmployeeBadge;
