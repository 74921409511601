import React, { memo, useCallback } from 'react';
import { FormSubmissionValues, SetFormSubmissionValues } from '@modules/forms/types';
import { ReceivedQuestionComponentSlider } from '@modules/learning/types/objects';
import QuestionSlider from '../components/question-slider';

export type SliderProps = {
  item: ReceivedQuestionComponentSlider;
  setValues: SetFormSubmissionValues;
  value: number;
  readOnly?: boolean;
};

const Slider = memo(({
  item, setValues, value, readOnly = false
}: SliderProps) => {

  const onChange = useCallback((newValue: number) => {
    // console.log('debug Slider newValue', newValue);
    setValues((values: FormSubmissionValues) => {
      return {
        ...values,
        [item.id]: newValue
      };
    });
  }, [setValues, item]);

  return (
    <QuestionSlider
      item={item}
      onChange={readOnly ? undefined : onChange}
      value={value}
    />
  );
});

export default Slider;
